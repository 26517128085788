<template>
    <div style="width: 300px">
        <a-form-model :model="form" ref="subForm" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item prop="photo" label="产品图片" :rules="validate({ name: 'value', msg: '请选择产品图片' })">
                <a-upload ref="upl" name="image" list-type="picture-card" class="avatar-uploader"
                    :headers="uploadHeaders" :show-upload-list="false" :action="action" :before-upload="beforeUpload"
                    @change="handleChange">
                    <img class="pImg" v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <a-icon :type="imgLoading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text">
                            上传图片
                        </div>
                    </div>
                    <span>注：支持格式JPG,PNG</span>
                </a-upload>
            </a-form-model-item>
        </a-form-model>
    </div>

</template>

<script>
import store from "@/store";
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
}
export default {
    data() {
        return {
            labelCol: { span: 2 },
            wrapperCol: { span: 10 },
            action: process.env.VUE_APP_API_URL + "/file/upload/image",
            uploadHeaders: {
                "device-type": "web",
                "ansuex-user-token": store.getters.getToken,
            },
            form: {
                photo: "",
            },
            imgLoading: false,
            imageUrl: ''

        }
    },
    methods: {
        beforeUpload(file) {
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                this.$message.error("请上传图片!");
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error("图片不能超过2MB!");
            }
            return isJpgOrPng && isLt2M;
        },
        handleChange(info) {
            if (info.file.status === "uploading") {
                this.imgLoading = true;
                return;
            }
            if (info.file.status === "done") {
                this.form.photo = info.file.response.data.path;
                getBase64(info.file.originFileObj, (imageUrl) => {
                    this.imageUrl = imageUrl;
                    this.imgLoading = false;
                });
            }
        },
        submit() {
            return new Promise((resolve, reject) => {
                this.$refs.subForm.validate(valid => {
                    if (valid) {
                        resolve(this.form)
                    } else {
                        console.log("error submit!!");
                        reject(false)
                        return false;
                    }
                })
            })

        }
    }
}
</script>

<style lang="less" scoped>
.pImg {
    width: 200px;
}
</style>