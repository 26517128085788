<template>
    <div>
        <!-- <SearchBar :formList="forms" @search="search" @reset="search"></SearchBar> -->
        <a-table size="small" bordered :columns="columns" :loading="tableLoading" :data-source="tableData"
            style="margin-top: 20px" :scroll="{ x: 'max-content' }" :rowKey="(record, index) => record.waybill_id"
            :pagination="pagination" @change="handlerPage"
            :row-selection="{ type: 'radio', columnTitle: '选择', columnWidth: 70, selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :customRow="customRow" :rowClassName="rowClassName">
            <template slot="fbaNo" slot-scope="record">
                <div style="display:flex;justify-content:space-between;align-items: center;">
                    <span>{{ record.fbaNo }}</span>
                    <a-popover title="FBA单号" trigger="click">
                        <template slot="content">
                            <div v-for="(item, index) in fbaList" :key="index">{{ item }}</div>
                        </template>
                        <a-button type="link" @click="checkFba(record)">查看</a-button>
                    </a-popover>
                </div>
            </template>
        </a-table>
    </div>
</template>

<script>
import {
    historyCargoes, getFba
} from "@/api/Waybill"
// import SearchBar from "@/components/SearchBar"
// import moment from "moment";
export default {
    components: {
        // SearchBar
    },
    data() {
        return {
            form: {
                page: 1,
                limit: 15,
                createdAt: ''
            },
            tableData: [],
            tableLoading: false,
            selectedRowKeys: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 15,
                showTotal: (total) => `共 ${total} 条`,
                showQuickJumper: true,
            },
            forms: [
                {
                    type: 'date',
                    label: "下单时间",
                    prop: 'createdAt'
                },
            ],
            columns: [
                {
                    title: '运单号',
                    dataIndex: 'waybill_no',
                    key: 'waybill_no',
                },
                {
                    title: '目的地',
                    dataIndex: 'country_name',
                    key: 'country_name',
                },
                {
                    title: '渠道',
                    dataIndex: 'channel_name',
                    key: 'channel_name',
                },
                {
                    title: '件数',
                    dataIndex: 'cargoes_num',
                    key: 'cargoes_num',
                },
                // {
                //     title: '下单时间',
                //     dataIndex: 'created_at',
                //     key: 'created_at',
                //     customRender: (text) => {
                //         let str = ""
                //         if (text) {
                //             str = moment(text * 1000).format("YYYY-MM-DD HH:mm:ss")
                //         } else if (text === 2) {
                //             str = "-"
                //         }
                //         return str;
                //     }
                // },
                {
                    title: '品名',
                    dataIndex: 'new_product_name',
                    key: 'new_product_name',
                },
            ],
            selectIndex: -1,
            fbaList: [],
            selectRow: null
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        // 点击的样式
        rowClassName(record, index) {
            return (index === this.selectIndex) ? 'row-active' : ''
        },
        // 点击的效果
        customRow(record, index) {
            return {
                on: {
                    click: () => {
                        this.selectIndex = index;
                        this.selectedRowKeys.length = 0;
                        this.selectedRowKeys.push(record.waybill_id);
                        this.selectRow = record;
                    }
                }
            }
        },
        onSelectChange(selectedRowKeys,selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectRow = selectedRows[0]
        },
        // 获取列表数据
        getData() {
            this.tableLoading = true;
            historyCargoes(this.form).then(res => {
                this.tableData = res.data.list || [];
                this.pagination.total = res.data.total;
                this.tableLoading = false;
            }).catch(() => {
                this.tableLoading = false;
            })
        },
        // 切换分页
        handlerPage(p) {
            this.form.page = p.current;
            this.form.limit = p.pageSize;
            this.pagination.current = p.current;
            this.pagination.pageSize = p.pageSize;
            this.getData();
        },
        checkFba(val) {
            getFba({
                waybillId: val.id
            }).then(res => {
                this.fbaList = res.data
            })
        },
        search(val) {
            this.form = Object.assign(this.form, val)
            this.getData()
        }
    }
}
</script>

<style lang="less" scoped>

</style>