/*
 * @Author: your name
 * @Date: 2021-12-13 14:24:52
 * @LastEditTime: 2021-12-13 14:35:54
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/api/Billing/index.js
 */
import httpService from "@/request"
// 获取运单费
export function waybillFeeList(params) {
    return httpService({
        url: `/finance/expense/lists`,
        method: 'post',
        data: params,
    })
}

// 获取账单费
export function billFeeList(params) {
    return httpService({
        url: `/finance/bill/lists`,
        method: 'post',
        data: params,
    })
}

// 获取发票详情
export function billInfo(params) {
    return httpService({
        url: `/order/waybill/info/all`,
        method: 'post',
        data: params,
    })
}
//账单运单列表
///finance/bill/waybills
export function billWaybills(params) {
    return httpService({
        url: `/finance/bill/waybills`,
        method: 'post',
        data: params,
    })
}
///finance/bill/waybill/costs
//详细费用单
export function billWayCosts(params) {
    return httpService({
        url: `/finance/bill/waybill/costs`,
        method: 'post',
        data: params,
    })
}
//运单费用明细
///finance/expense/waybill/costs
export function expenseCosts(params) {
    return httpService({
        url: `/finance/expense/waybill/costs`,
        method: 'post',
        data: params,
    })
}