<template>
    <div>
        <div><span></span><a class="btn-a" @click="openTable">{{ title }}</a></div>
        <ly-drawer :title="model.title" :visible="model.visible" :width="model.width" :confirm-loading="confirmLoading"
            @ok="handleOk" @cancel="handleCancel">
            <a-form-model layout="inline" :model="form">
                <a-form-model-item label="姓名">
                    <a-input v-model="form.keyword" />
                </a-form-model-item>
                <a-form-model-item>
                    <a-button-group>
                        <a-button type="primary" @click="search">搜索</a-button>
                        <a-button @click="reset">重置</a-button>
                    </a-button-group>
                </a-form-model-item>
            </a-form-model>
            <a-table size="small" style="margin-top: 20px" :scroll="{ x: 'max-content' }" :customRow="rowClick" :rowSelection="rowSelection" bordered
                :columns="columns" :data-source="tableData" :loading="tableLoading" :rowKey="(record,index) => record.id" :pagination="pagination"
                @change="handlerPage"></a-table>
        </ly-drawer>
    </div>
</template>

<script>
import { noFbaAddress } from "@/api/comm"
export default {
    props: {
        addressId: {
            type: [String, Number],
            default: "",
        },
    },
    model: {
        prop: "addressId",
        event: "returnAddress",
    },
    computed: {
        rowSelection() {
            return {
                type: "radio",
                columnTitle: "选择",
                columnWidth: 70,
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    console.log(
                        `selectedRowKeys: ${selectedRowKeys}`,
                        "selectedRows: ",
                        selectedRows
                    );
                    this.selectedRowKeys = selectedRowKeys;
                    let row = selectedRows[0];
                    this.chooseRow = row;
                    this.content = `${row.country_name} - ${row.name} - (${row.address}) `;
                },
            };
        },
    },
    data() {
        return {
            content: "",
            title: "选择非FBA地址",
            form: {
                keyword: "",
                limit: 20,
                page: 1,
            },
            confirmLoading: false,
            tableLoading: false,
            selectedRowKeys: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 20,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            model: {
                title: "",
                visible: false,
                width: "1200px",
            },
            tableData: [],
            columns: [
                {
                    title: "国家名称",
                    dataIndex: "country_name",
                    key: "country_name",
                },
                {
                    title: "城市",
                    dataIndex: "city",
                    key: "city",
                },
                {
                    title: "州",
                    dataIndex: "state",
                    key: "state",
                },
                {
                    title: "公司",
                    dataIndex: "corporation",
                    key: "corporation",
                },
                {
                    title: "姓名",
                    dataIndex: "name",
                    key: "name",
                },
                {
                    title: "区号",
                    dataIndex: "country_area_code",
                    key: "country_area_code",
                },
                {
                    title: "电话",
                    dataIndex: "phone",
                    key: "phone",
                },
                {
                    title: "邮箱",
                    dataIndex: "email",
                    key: "email",
                },
                {
                    title: "邮编",
                    dataIndex: "zip_code",
                    key: "zip_code",
                },
                {
                    title: "地址",
                    dataIndex: "address",
                    key: "address",
                }
            ],
            chooseRow: {}
        }
    },
    methods: {
        openTable() {
            this.getData().then(() => {
                this.model.visible = true;
            });
        },
        getData() {
            return new Promise((resolve, reject) => {
                this.tableLoading = true;
                noFbaAddress(this.form)
                    .then((res) => {
                        let arr = res.data.list || [];
                        arr.sort(function (a, b) {
                            return a.fba_name > b.fba_name
                        })
                        this.tableData = arr;
                        this.model.visible = true;
                        this.tableLoading = false;
                        resolve(true);
                    })
                    .catch(() => {
                        reject(false);
                    });
            });
        },
        handleOk() {
            this.$emit("returnAddress", this.selectedRowKeys.toString() * 1);
            this.$emit("change", this.selectedRowKeys[0]);
            this.$emit('getRow', this.chooseRow);
            this.model.visible = false;
        },
        handleCancel() {
            this.model.visible = false;
        },
        reset() {
            this.form.storehouseName = "";
        },
        search() {
            this.form.page = 1;
            this.getData();
        },
        handlerPage(p) {
            this.form.page = p.current;
            this.form.limit = p.pageSize;
            this.pagination.current = p.current;
            this.pagination.pageSize = p.pageSize;
            this.getData();
        },
        //table行点击
        rowClick(record) {
            return {
                on: {
                    click: () => {
                        this.chooseRow = record;
                        this.selectedRowKeys = [record.id];
                        this.content = `${record.country_name} - ${record.name} - (${record.address}) `;
                    },
                },
            };
        },
    }
}
</script>

<style lang="less">
</style>