const columnlen = {
    '0': 140,
    '1': 80,
    '2': 100,
    '4': 140,
    '6': 140,
    '7': 70,
    '9': 70,
    '10': 70,
    '11': 70,
    '12': 260,
    '13': 260,
}
export default columnlen