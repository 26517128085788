const sheelHeader = [
  {
    r: "0",
    c: "0",
    prop: "fbaNo",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "货箱编号*",
      v: "货箱编号*",
    },
  },
  {
    r: "0",
    c: "1",
    prop: "poNumber",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "PO Number*",
      v: "PO Number*",
    },
  },
  {
    r: "0",
    c: "2",
    prop: "enItemName",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "产品英文品名*",
      v: "产品英文品名*",
    },
  },
  {
    r: "0",
    c: "3",
    prop: "zhItemName",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "产品中文品名*",
      v: "产品中文品名*",
    },
  },
  {
    r: "0",
    c: "4",
    prop: "declaredValue",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "产品申报单价*（USD）",
      v: "产品申报单价*（USD）",
    },
  },
  {
    r: "0",
    c: "5",
    prop: "num",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "产品申报数量*",
      v: "产品申报数量*",
    },
  },
  {
    r: "0",
    c: "6",
    prop: "totalPrice",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "产品申报总价*（USD)",
      v: "产品申报总价*（USD)",
    },
  },
  {
    r: "0",
    c: "7",
    prop: "enMaterial",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "产品材质*",
      v: "产品材质*",
    },
  },
  {
    r: "0",
    c: "8",
    prop: "hsCode",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "产品海关编码*",
      v: "产品海关编码*",
    },
  },
  {
    r: "0",
    c: "9",
    prop: "enPurpose",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "产品用途*",
      v: "产品用途*",
    },
  },
  {
    r: "0",
    c: "10",
    prop: "brand",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "产品品牌*",
      v: "产品品牌*",
    },
  },
  {
    r: "0",
    c: "11",
    prop: "model",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "产品型号*",
      v: "产品型号*",
    },
  },
  {
    r: "0",
    c: "12",
    prop: "photo",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "产品图片链接*",
      v: "产品图片链接*",
    },
  },
  {
    r: "0",
    c: "13",
    prop: "amazonUrl",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "产品销售链接",
      v: "产品销售链接",
    },
  },
  {
    r: "0",
    c: "14",
    prop: "weight",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "货箱重量（KG）",
      v: "货箱重量（KG）",
    },
  },
  {
    r: "0",
    c: "15",
    prop: "length",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "货箱长度（CM）",
      v: "货箱长度（CM）",
    },
  },
  {
    r: "0",
    c: "16",
    prop: "width",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "货箱宽度（CM）",
      v: "货箱宽度（CM）",
    },
  },
  {
    r: "0",
    c: "17",
    prop: "height",
    v: {
      fc: "#F98300",
      fs: 9,
      m: "货箱高度（CM）",
      v: "货箱高度（CM）",
    },
  },
];

export default sheelHeader;
