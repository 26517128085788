export default [
  {
    title: "货件编号",
    dataIndex: "fbaNo",
    key: "fbaNo",
  },
  {
    title: "PO Number",
    dataIndex: "poNumber",
    key: "poNumber",
  },
  {
    title: "产品英文品名",
    dataIndex: "enItemName",
    key: "enItemName",
  },
  {
    title: "产品中文品名",
    dataIndex: "zhItemName",
    key: "zhItemName",
  },
  {
    title: "产品申报单价",
    dataIndex: "declaredValue",
    key: "declaredValue",
  },
  {
    title: "产品申报数量",
    dataIndex: "num",
    key: "num",
  },
  {
    title: "产品申报总价",
    dataIndex: "totalPrice",
    key: "totalPrice",
    scopedSlots: {
      customRender: "totalPrice",
    },
  },
  {
    title: "产品材质",
    dataIndex: "enMaterial",
    key: "enMaterial",
  },
  {
    title: "产品海关编码",
    dataIndex: "hsCode",
    key: "hsCode",
  },
  {
    title: "产品用途",
    dataIndex: "enPurpose",
    key: "enPurpose",
  },
  {
    title: "产品品牌",
    dataIndex: "brand",
    key: "brand",
  },
  {
    title: "产品型号",
    dataIndex: "model",
    key: "model",
  },
  {
    title: "产品图片链接",
    dataIndex: "photo",
    key: "photo",
    scopedSlots: {
      customRender: "photo",
    },
  },
  {
    title: "产品销售链接",
    dataIndex: "amazonUrl",
    key: "amazonUrl",
  },
  {
    title: "货箱重量（KG）",
    dataIndex: "weight",
    key: "weight",
  },
  {
    title: "货箱长度（CM）",
    dataIndex: "length",
    key: "length",
  },
  {
    title: "货箱宽度（CM）",
    dataIndex: "width",
    key: "width",
  },
  {
    title: "货箱高度（CM）",
    dataIndex: "height",
    key: "height",
  },
  {
    title: "操作",
    key: "action",
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
