<template>
  <a-form-model
    ref="subForm"
    :model="form"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item
      prop="file"
      label="上传Excel"
      :rules="validate({ name: 'value', msg: '请上传文件' })"
    >
      <input
        type="file"
        ref="import"
        id="impExcel"
        @change="handleChange"
        accept=".xlsx,.xls"
      />
    </a-form-model-item>
    <div>
      <a class="btn-a" href="/xlxs/装箱清单导入模_last.xls">下载发票模版</a>
      <!-- <a class="btn-a" @click="downFile">下载发票模版</a> -->
    </div>
    <div style="color: red">
      <a-icon
        type="exclamation-circle"
      />请下载发票专用模版，如使用其他Excel会发生数据错误
    </div>
  </a-form-model>
</template>

<script>
import { intOrFloat } from "@/utils/plugs";
export default {
  props: {
    cargoNos: {
      type: Array,
      default: () => [],
    },
    cargoIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      labelCol: { span: 2 },
      wrapperCol: { span: 14 },
      form: {
        file: null,
      },
      tableData: [],
      excelData: [],
      addressInfo: {
        vat: undefined,
        // amazonReferenceId: undefined, // 亚马逊ID
        name: undefined, // 收件人
        phone: undefined, // 收件人电话
        remark: undefined, // 发票备注
        address: undefined, // 地址详情
        corporation: undefined, // 公司
        state: undefined, // 州
        city: undefined, // 城市
        zipCode: undefined, // 目的地邮编
      },
      cf: {},
    };
  },
  methods: {
    handleChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.form.file = files[0].name;
      this.impExcel(e).then((res) => {
        this.form.file = res;
        let arr = res || [];
        console.log("解析文件==>", arr);
        let fields = [
          "货箱编号*",
          "PO Number*",
          "产品英文品名*",
          "产品中文品名*",
          "产品申报单价*（USD）",
          "产品申报数量*",
          "产品申报总价*（USD）",
          "产品材质*",
          "产品海关编码*",
          "产品用途*",
          "产品品牌*",
          "产品型号*",
          "产品图片链接*",
          "产品销售链接*",
          "货箱重量（KG）",
          "货箱长度（CM）",
          "货箱宽度（CM）",
          "货箱高度（CM）",
        ];
        let fieldsName = [
          "fbaNo",
          "poNumber",
          "enItemName",
          "zhItemName",
          "declaredValue",
          "num",
          "totalPrice",
          "enMaterial",
          "hsCode",
          "enPurpose",
          "brand",
          "model",
          "photo",
          "amazonUrl",
          "weight",
          "length",
          "width",
          "height",
        ];
        let addressFieldName = {
          2: "name",
          3: "corporation",
          4: "address",
          7: "city",
          8: "state",
          9: "zipCode",
          11: "phone",
          // '收件人邮箱': 12,
          20: "vat",
          21: "remark",
        };
        let flag = false;
        let fieldsIndex = [];
        let a2 = [];
        let sor = new Map();
        sor.set("Amazon Reference ID*", "amazonReferenceId");
        sor.set("收件人姓名*", "addressId");
        sor.set("VAT号", "vat");
        sor.set("交税方式*", "isDeferred");
        sor.set("报关方式*", "tradeType");
        sor.set("清关方式", "isSeparateCustomsClearance");
        let cf = {};
        let lastFbaNo = {};
        arr.forEach((item, i) => {
          if (i <= 21) {
            if (Reflect.has(addressFieldName, `${i}`)) {
              this.addressInfo[addressFieldName[i]] = item[1];
            }
          } else {
            if (flag) {
              // 装箱清单处理
              let obj = {};
              obj.id = this.uuid();
              obj.import = 1;
              fieldsName.forEach((val, index) => {
                obj[val] = intOrFloat(item[fieldsIndex[index]]);
                if (val === "fbaNo") {
                  obj[val] = obj[val] + "";
                  if (obj["fbaNo"] != "") {
                    lastFbaNo = obj["fbaNo"];
                  } else {
                    if (item[0] == "" && item[1] != "")
                      obj["fbaNo"] = lastFbaNo;
                  }
                }
              });
              a2.push(obj);
            } else {
              // 装箱清单处理
              if (fields.includes(item[0])) {
                flag = true;
                fields.forEach((value) => {
                  fieldsIndex.push(item.indexOf(value));
                });
              } else {
                // 其他数据处理
                let psr = sor.get(item[0]);
                if (psr) {
                  let str = "";
                  if (
                    ["isDeferred", "isSeparateCustomsClearance"].includes(psr)
                  ) {
                    str = !item[1] || item[1] === "否" ? 0 : 1;
                  } else if (psr == "tradeType") {
                    str = !item[1] || item[1] === "否" ? 2 : 1;
                  } else {
                    str = item[1];
                  }
                  cf[psr] = str;
                }
              }
            }
          }
        });
        this.cf = cf;
        this.excelData = this.createExcelData(a2);
        this.tableData = this.createExcelData(a2);
      });
    },
    createExcelData(arr = []) {
      const a = [];
      arr.forEach((ele) => {
        let obj = Object.assign({}, ele);
        if (obj.fbaNo) {
          a.push(obj);
        }
      });
      return a;
    },
    submit() {
      return new Promise((resolve, reject) => {
        console.log("提交发票1");
        this.$refs.subForm.validate((valid) => {
          if (valid) {
            resolve({
              td: this.tableData,
              ed: this.excelData,
              cf: this.cf,
              addressInfo: this.addressInfo,
            });
          } else {
            reject(false);
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
