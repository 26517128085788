<template>
  <div v-if="isShow" class="page-container">
    <a-form-model
      size="small"
      ref="fform"
      :model="subForm"
      :labelCol="{ span: 8 }"
      :wrapperCol="{ span: 16 }"
    >
      <TabHeader title="运单详情"></TabHeader>
      <div class="fd-outer">
        <a-row>
          <a-col :span="8">
            <span class="fd-s1">运单编号：{{ form.waybill_no }}</span>
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">运单类型：</span
              ><span class="s2">{{ form.type | typeStr }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2" v-if="!form.has_invoice"
              ><span class="s1">发票：</span
              ><span class="s2" style="color: #ff0000">未做发票</span></span
            >
            <span class="fd-s2" v-else
              ><span class="s1">发票：</span
              ><span class="s2">已做发票</span></span
            >
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <div class="fd-address">
              <div class="d1">
                <div class="s1">起运点</div>
                <div class="s2">{{ form.original }}</div>
              </div>
              <div class="d2">
                <img src="../../../../assets/images/111.png" />
              </div>
              <div class="d1">
                <div class="s1">目的国</div>
                <div class="s2">{{ form.destination }}</div>
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <span class="s1">渠道：{{ form.channel_name }}</span>
          </a-col>
          <a-col :span="8">
            <span class="s1">客户备注：{{ form.remark }}</span>
          </a-col>
        </a-row>
        <div class="fd-line"></div>
        <div class="fd-once">
          <div class="fd-inner">
            <div class="fd-inner-title">目的地详情</div>
            <a-col :span="12">
              <a-form-model-item
                label="是否FBA"
                prop="type"
                :rules="validate({ name: 'value', msg: '请选择类型' })"
              >
                <a-radio-group
                  style="width: 260px"
                  v-model="subForm.type"
                  @change="changeType"
                >
                  <a-radio :value="1">FBA</a-radio>
                  <a-radio :value="2">非FBA</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="目的地"
                prop="addressId"
                :rules="validate({ name: 'value', msg: '请选择地址' })"
              >
                <a-input-group compact>
                  <a-input
                    style="width: calc(100% - 130px)"
                    v-model="subForm.name"
                  ></a-input>
                  <chooseFbaAdr
                    v-model="subForm.addressId"
                    :country="form.destination"
                    @getRow="handlerAdress"
                    v-if="subForm.type === 1"
                  ></chooseFbaAdr>
                  <chooseNoFbaAdr
                    v-model="subForm.addressId"
                    v-if="subForm.type === 2"
                    @getRow="handlerAdress"
                  >
                  </chooseNoFbaAdr>
                </a-input-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="目的地邮编"
                prop="zipCode"
                :rules="validate({ name: 'value', msg: '请输入目的地邮编' })"
              >
                <a-input
                  style="width: 260px"
                  v-model="subForm.zipCode"
                  placeholder="目的地邮编"
                >
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="城市"
                prop="city"
                :rules="validate({ name: 'value', msg: '请输入目的地邮编' })"
              >
                <a-input
                  style="width: 260px"
                  v-model="subForm.city"
                  placeholder="城市"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="州">
                <a-input
                  style="width: 260px"
                  v-model="subForm.state"
                  placeholder="州"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="公司">
                <a-input
                  style="width: 260px"
                  v-model="subForm.corporation"
                  placeholder="公司"
                >
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="地址详情"
                prop="address"
                :rules="validate({ name: 'value', msg: '请输入地址详情' })"
              >
                <a-textarea
                  style="width: 260px"
                  :rows="4"
                  v-model="subForm.address"
                  placeholder="地址详情"
                >
                </a-textarea>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="subForm.type === 1">
              <a-form-model-item label="发票备注" prop="address">
                <a-textarea
                  style="width: 260px"
                  :rows="4"
                  v-model="subForm.remark"
                  placeholder="发票备注"
                >
                </a-textarea>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="subForm.type === 1">
              <a-form-model-item
                label="收件人"
                prop="recipient"
                :rules="[{ required: true, message: '请填写收件人!' }]"
              >
                <a-textarea
                  style="width: 260px"
                  :rows="4"
                  v-model="subForm.recipient"
                  placeholder="收件人"
                ></a-textarea>
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="12">
              <a-form-model-item label="邮箱">
                <a-input
                  style="width: 260px"
                  v-model="subForm.recipient"
                  placeholder="邮箱"
                ></a-input>
              </a-form-model-item>
            </a-col> -->
            <a-col :span="12" v-if="subForm.type === 2">
              <a-form-model-item label="电话">
                <a-input
                  style="width: 260px"
                  v-model="subForm.phone"
                  placeholder="电话"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="subForm.type === 2">
              <a-form-model-item label="发票备注" prop="address">
                <a-textarea
                  style="width: 260px"
                  :rows="4"
                  v-model="subForm.remark"
                  placeholder="发票备注"
                >
                </a-textarea>
              </a-form-model-item>
            </a-col>
          </div>
          <div class="fd-inner">
            <div class="fd-inner-title">报关信息</div>
            <a-col :span="24">
              <a-form-model-item label="VAT税号">
                <a-input
                  v-model="subForm.vat"
                  allowClear
                  placeholder="VAT税号"
                  style="width: 260px"
                >
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item
                prop="tradeType"
                label="报关类型"
                :rules="validate({ name: 'value', msg: '请选择贸易类型' })"
              >
                <a-radio-group v-model="subForm.tradeType" style="width: 260px">
                  <a-radio :value="1">一般报关类型</a-radio>
                  <a-radio :value="2">不报关</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item
                prop="isDeferred"
                label="交税方式"
                :rules="validate({ name: 'value', msg: '请选择交税方式' })"
              >
                <a-radio-group
                  v-model="subForm.isDeferred"
                  style="width: 260px"
                >
                  <a-radio
                    :value="1"
                    v-if="
                      form.channel_tax_types &&
                      form.channel_tax_types.includes(1)
                    "
                    >包税
                  </a-radio>
                  <a-radio
                    :value="2"
                    v-if="
                      form.channel_tax_types &&
                      form.channel_tax_types.includes(2)
                    "
                    >不包税
                  </a-radio>
                  <a-radio
                    :value="3"
                    v-if="
                      form.channel_tax_types &&
                      form.channel_tax_types.includes(3)
                    "
                    >自税递延
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>

            <a-col :span="24">
              <a-form-model-item
                label="Amazon Reference ID"
                :labelCol="{ span: 10 }"
                :wrapperCol="{ span: 13 }"
              >
                <a-textarea
                  v-model="subForm.amazonReferenceId"
                  allowClear
                  :rows="4"
                  placeholder="Amazon Reference ID"
                  style="width: 260px"
                ></a-textarea>
              </a-form-model-item>
            </a-col>
          </div>
        </div>
        <div class="fd-line"></div>
        <div class="fd-inner">
          <div class="fd-inner-title">
            货件数量{{ isCNum }}箱-装箱信息(共{{ cargoesNum }}箱)
          </div>
          <div class="fd-dm">
            <div style="margin-top: 10px">
              <a-button
                style="margin-left: 10px"
                type="primary"
                @click="importInvoices"
                >导入发票</a-button
              >
              <a-button
                style="margin-left: 10px"
                type="primary"
                @click="openExcel"
                >发票在线编辑</a-button
              >
              <!-- <a-button style="margin-left: 10px" type="primary" @click="openRecord">装箱清单记录</a-button> -->
            </div>
            <div class="fd-dm-d1">
              <span>申报币种：{{ form.currency_name }}</span>
              <span>总申报价值：{{ tableTotalVal }}</span>
            </div>
          </div>
          <a-table
            size="small"
            style="margin-top: 10px"
            :row-selection="rowSelection"
            bordered
            :columns="columns"
            :data-source="tableData"
            :scroll="{ x: 'max-content' }"
            :rowKey="(record, index) => index"
            :pagination="pagination"
            :customRow="customRow"
            :rowClassName="rowClassName"
          >
            <template slot="totalPrice" slot-scope="text, record">
              <span>{{ (record.num * record.declaredValue).toFixed(2) }}</span>
            </template>
            <template slot="photo" slot-scope="text, record">
              <a-popover placement="bottom">
                <template slot="content">
                  <img
                    style="width: 200px"
                    :src="record.photo"
                    v-if="record.photo"
                  />
                </template>
                <template slot="title">
                  <span>图片</span>
                </template>
                <a class="btn-a" style="margin-left: 10px">查看</a>
              </a-popover>
            </template>
            <template slot="action" slot-scope="record">
              <a class="btn-a" type="link" @click="upImg(record)">上传图片</a>
            </template>
          </a-table>
        </div>
        <div class="fd-line"></div>
        <div class="fd-inner">
          <a-form-model-item
            :labelCol="{ span: 1 }"
            prop="materialCateIds"
            label="材质"
            :rules="validate({ name: 'value', msg: '请选择材质' })"
          >
            <a-checkbox-group
              v-model="subForm.materialCateIds"
              :options="materials"
            >
            </a-checkbox-group>
          </a-form-model-item>
        </div>
        <div style="margin-top: 20px" align="right">
          <a-button type="primary" @click="goBack"> 返回 </a-button>
          <a-button
            style="margin-left: 15px"
            type="primary"
            ghost
            @click="save"
          >
            保存草稿
          </a-button>
          <a-button
            style="margin-left: 15px"
            :loading="iconLoading"
            type="primary"
            @click="make"
          >
            提交发票
          </a-button>
        </div>
      </div>
    </a-form-model>
    <a-modal
      :title="modal.title"
      :maskClosable="false"
      v-model="modal.visible"
      :width="modal.width"
      @ok="handleOk"
    >
      <importInvoices
        ref="iiv"
        :cargoIds="cargoIds"
        :cargoNos="cargoNos"
        v-if="modal.visible && modal.index === 4"
      >
      </importInvoices>
    </a-modal>
    <a-modal
      :title="modalx.title"
      :maskClosable="false"
      v-model="modalx.visible"
      :width="modalx.width"
      @ok="handleOkx"
    >
      <addPproductLibrary
        ref="apl"
        v-if="modalx.visible && modalx.index === 1"
      ></addPproductLibrary>
    </a-modal>
    <lyDrawer
      :visible.sync="drawerShow"
      width="1400px"
      @cancel="drawerClose"
      @ok="drawerSubmit"
    >
      <waybillHistory ref="wlh" v-if="drawerShow"></waybillHistory>
    </lyDrawer>
    <EditTable
      v-if="editTable"
      :sheelData="gridData"
      :sheelHeader="sheelHeader"
      :columnlen="columnlen"
      sheetName="装箱清单"
      @cancel="editTableClose"
      @excelData="getExcelData"
    ></EditTable>
  </div>
</template>

<script>
import { billInfo } from "@/api/Billing";
import { makeInvoice, invoiceDraft, invoiceEdit } from "@/api/Waybill";
import { materialList } from "@/api/comm";
import columns from "./curd/columns";
import importInvoices from "./formComm/importInvoices";
import { Decimal } from "decimal.js";
import chooseFbaAdr from "./formComm/chooseFbaAdr.vue";
import chooseNoFbaAdr from "./formComm/chooseNoFbaAdr.vue";
import addPproductLibrary from "./formComm/addPproductLibrary.vue";
import sheelHeader from "./curd/sheelHeader";
import columnlen from "./curd/columnlen";
import waybillHistory from "./formComm/waybillHistory.vue";
import _ from "lodash";
export default {
  name: "makeAnInvoice",
  components: {
    importInvoices,
    chooseFbaAdr,
    chooseNoFbaAdr,
    addPproductLibrary,
    waybillHistory,
  },
  watch: {
    tableData: {
      handler(val) {
        let price = 0;
        let carr = [];
        val.forEach((i) => {
          if (isNaN(Number(i.num))) {
            i.num = 0;
          } else {
            i.num = i.num || 0;
          }
          if (isNaN(Number(i.declaredValue))) {
            i.declaredValue = 0;
          } else {
            i.declaredValue = i.declaredValue || 0;
          }
          let t = new Decimal(i.num).mul(new Decimal(i.declaredValue));
          price += t * 1;
          if (!carr.includes(i.fbaNo)) {
            carr.push(i.fbaNo);
          }
        });
        this.cargoesNum = carr.length;
        this.tableTotalVal = price.toFixed(2);
      },
      deep: true,
    },
  },
  data() {
    return {
      selectIndex: null,
      sheelHeader: sheelHeader,
      columnlen: columnlen,
      id: "",
      isShow: false,
      columns: columns,
      tableData: [],
      Decimal,
      form: {},
      cargoIds: [],
      cargoNos: [],
      tableTotalVal: 0,
      cargoesNum: 0,
      isCNum: 0,
      currencyName: "",
      subForm: {
        vat: "",
        tradeType: undefined,
        isDeferred: undefined,
        amazonReferenceId: "",
        corporation: "",
        city: "",
        state: "",
        haveSafe: 0,
        address: "",
        isSeparateCustomsClearance: 0,
        materialCateIds: [],
        type: 1,
        addressId: null,
        name: "",
        phone: "",
        zipCode: "",
        recipient: "",
      },
      materials: [],
      chooseRow: {},
      chooseParent: {},
      addType: 0,
      productId: "",
      selectedRowKeys: [],
      pagination: {
        defaultCurrent: 1,
        defaultPageSize: 20,
        showTotal: (total) => `共 ${total} 条`,
        showQuickJumper: true,
      },
      rowSelection: {
        onChange: (selectedRowKeys) => {
          this.selectedRowKeys = selectedRowKeys;
        },
      },
      modal: {
        title: "",
        width: "",
        visible: false,
        index: -1,
      },
      modalx: {
        title: "",
        width: "",
        visible: false,
        index: -1,
      },
      editTable: false,
      drawerShow: false,
      gridData: [],
      iconLoading: false,
      type: 0, //1制作发票 0未制做
    };
  },
  created() {
    let id = this.$route.query.id * 1;
    this.type = this.$route.query.has_invoice;
    this.getDetails(id);
    this.materialList();
  },
  methods: {
    // 点击的样式
    rowClassName(record, index) {
      return index === this.selectIndex ? "row-active" : "";
    },
    // 点击的效果
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.selectIndex = index;
            console.log(this.selectIndex);
          },
        },
      };
    },
    //
    changeType() {
      this.subForm.name = "";
      this.subForm.addressId = "";
      this.subForm.zipCode = "";
      this.subForm.phone = "";
      this.subForm.address = "";
      this.subForm.city = "";
      this.subForm.state = "";
      this.subForm.corporation = "";
      this.subForm.recipient = "";
      this.subForm.remark = "";
    },
    // 获取材质
    materialList() {
      materialList().then((res) => {
        let list = res.data;
        const arr = [];
        list.forEach((ele) => {
          let obj = {
            label: ele.name,
            value: ele.id,
          };
          arr.push(obj);
        });
        this.materials = arr;
      });
    },
    // 获取运单详情
    getDetails(id) {
      return new Promise((resolve, reject) => {
        this.id = id;
        if (id) {
          let obj = {
            waybillId: id,
          };
          billInfo(obj)
            .then((res) => {
              this.form = res.data;
              this.currencyName = this.form.currency_name;
              this.subForm.vat = this.form.vat;
              //   if (this.form.material_cates && this.form.material_cates.length) {
              //     this.subForm.materialCateIds = this.form.material_cates;
              //   } else {
              //     this.subForm.materialCateIds =
              //       this.form.new_material_cate_items.map((t) => t.id);
              //   }
              const materialCateIds = this.form.new_material_cate_items || [];
              this.subForm.materialCateIds = materialCateIds.map((t) => t.id);
              this.subForm.isSeparateCustomsClearance =
                this.form.is_separate_customs_clearance;
              this.subForm.tradeType = this.form.trade_type || undefined;
              this.subForm.isDeferred = this.form.is_deferred;
              this.subForm.haveSafe = this.form.have_safe;
              this.subForm.amazonReferenceId = this.form.amazon_reference_id;
              this.tableTotalVal = this.form.declared_value;
              this.subForm.addressId = this.form.address.address_id;
              this.subForm.name = this.form.address.name;
              this.subForm.address = this.form.address.address;
              this.subForm.state = this.form.address.state;
              this.subForm.city = this.form.address.city;
              this.subForm.corporation = this.form.address.corporation;
              this.subForm.recipient = this.form.address.recipient;
              this.subForm.type = this.form.type || 1;
              this.subForm.zipCode = this.form.address.zip_code;
              this.subForm.phone = this.form.address.phone;
              this.subForm.remark = this.form.invoice_remark;
              this.isCNum = this.form.cargoes_num;
              const cargoes = res.data.cargoes || [];
              const arr = [];
              const ids = [];
              const nos = [];
              cargoes.forEach((ele) => {
                ele.cargoId = ele.id;
                ele.cargoNo = ele.cargo_no;
                ele.fbaNo =
                  ele.fba_no || ele.cargo_no.substring(ele.cargo_no.length - 6);
                ids.push(ele.id);
                nos.push(ele.fbaNo);
                const items = ele.items || [];
                items.forEach((e) => {
                  let obj = {};
                  obj.productId = e.id;
                  obj.enItemName = e.en_item_name;
                  obj.zhItemName = e.zh_item_name;
                  obj.num = e.goods_num;
                  obj.enMaterial = e.en_material;
                  obj.zhMaterial = e.zh_material || "";
                  obj.hsCode = e.hs_code;
                  obj.enPurpose = e.en_purpose;
                  obj.zhPurpose = e.zh_purpose || "";
                  obj.declaredValue = e.declared;
                  obj.photo = e.photo ? e.photo : "";
                  obj.totalPrice = (e.goods_num * e.declared).toFixed(2);
                  obj.import = 0;
                  obj.cargoId = ele.id;
                  obj.fbaNo = ele.fba_no;
                  obj.poNumber = e.po_number;
                  arr.push(obj);
                });
              });
              this.cargoIds = ids;
              this.cargoNos = nos;
              this.tableData = arr;
              this.gridData = arr;
              this.isShow = true;
              resolve(true);
            })
            .catch(() => {
              reject(false);
            });
        } else {
          reject(false);
        }
      });
    },
    // 装箱清单记录
    openRecord() {
      this.drawerShow = true;
    },
    drawerClose() {
      this.drawerShow = false;
    },
    // 导入发票
    importInvoices() {
      this.modal.index = 4;
      this.modal.width = "460px";
      this.modal.title = "导入发票";
      this.modal.visible = true;
    },
    // 删除
    del(row, data) {
      let n = data.findIndex((ele) => ele.id === row.id);
      data.splice(n, 1);
    },
    // 上传excel 的效果弹出框的确定
    handleOk() {
      if (this.modal.index === 4) {
        this.$refs.iiv.submit().then((res) => {
          this.tableData = res.td;
          Object.assign(this.subForm, res.addressInfo);
          this.modal.visible = false;
        });
      }
    },
    // 添加图片地址
    handleOkx() {
      if (this.modalx.index === 1) {
        this.$refs.apl.submit().then((res) => {
          if (res.photo) {
            this.chooseRow.photo = res.photo.includes("http")
              ? res.photo
              : process.env.VUE_APP_CDN + res.photo;
          }
          this.modalx.visible = false;
        });
      }
    },
    // 制作发票
    make() {
      this.$refs.fform.validate((valid) => {
        if (valid) {
          let url = "";
          this.type == 1 ? (url = invoiceEdit) : (url = makeInvoice);
          this.iconLoading = true;
          this.subForm.waybillId = this.id;
          this.subForm.cargoes = this.subData(this.tableData).map((ele) => {
            ele.products = ele.products.map((e) => {
              e.num = Number(e.num);
              return e;
            });
            return ele;
          });
          Reflect.deleteProperty(this.subForm, "addressId");
          this.subForm.remark = String(this.subForm.remark);
          url(this.subForm)
            .then(() => {
              let msg = "";
              this.type == 1 ? (msg = "修改发票成功") : (msg = "发票制作成功");
              this.$message.success(msg);
              this.iconLoading = false;
            })
            .catch(() => {
              this.iconLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 保存发票
    save() {
      this.subForm.waybillId = this.id;
      this.subForm.cargoes = this.subData(this.tableData);
      this.subForm.remark = String(this.subForm.remark);
      invoiceDraft(this.subForm).then(() => {
        this.$message.success("发票保存成功");
      });
    },
    // 制作发票errorMsg
    errorFun(row) {
      let arrParams = [
        "enItemName",
        "zhItemName",
        "declaredValue",
        "num",
        "totalPrice",
        "enMaterial",
        "hsCode",
        "enPurpose",
      ];
      let arrEorr = [
        "产品英文品名",
        "产品中文品名",
        "产品申报单价",
        "产品申报数量",
        "产品申报总价",
        "产品材质",
        "产品海关编码",
        "产品用途",
      ];
      for (let i = 0; i < arrParams.length; i++) {
        let key = arrParams[i];
        if (!row[key]) {
          this.$message.error(arrEorr[i] + "不能为空");
          return false;
        }
      }
      return true;
    },
    // 提交数据处理
    subData(arr = []) {
      let a1 = [];
      for (let i = 0; i < arr.length; i++) {
        let row = Object.assign({}, arr[i]);
        let b = this.errorFun(row);
        if (!b) {
          return a1;
        }
        row.hsCode = row.hsCode + "";
        row.itemZhName = row.zhItemName + "";
        row.itemEnName = row.enItemName + "";
        if (!row.photo) row.photo = "无";
        // row.photo = row.photo
        //   ? row.photo.toString().replace(process.env.VUE_APP_CDN, "")
        //   : "无";
        row.amazonUrl = row.amazonUrl ? row.amazonUrl + "" : "无";
        row.amazonAsin = row.amazonAsin + "";
        row.brand = row.brand + "";
        row.model = row.model + "";
        row.enPurpose = row.enPurpose + "";
        row.enMaterial = row.enMaterial + "";
        row.fbaNo = row.fbaNo + "";
        row.poNumber = row.poNumber + "";
        row.weight = row.weight ? row.weight + "" : "1";
        row.length = row.length ? row.length + "" : "1";
        row.width = row.width ? row.width + "" : "1";
        row.height = row.height ? row.height + "" : "1";
        let n = a1.findIndex((ele) => ele.fbaNo === row.fbaNo);
        if (n === -1) {
          let obj = {};
          obj.fbaNo = row.fbaNo;
          obj.weight = row.height;
          obj.length = row.length;
          obj.width = row.width;
          obj.height = row.height;
          obj.products = [row];
          a1.push(obj);
        } else {
          a1[n].products.push(row);
        }
      }
      return a1;
    },
    // 上传图片
    upImg(row) {
      this.chooseRow = row;
      this.modalx.index = 1;
      this.modalx.width = "360px";
      this.modalx.visible = true;
    },
    // 打开excel编辑
    openExcel() {
      this.gridData = _.cloneDeep(this.tableData);
      this.editTable = true;
    },
    getExcelData(val) {
      this.editTable = false;
      const fbaNoFlag = val.findIndex((t) => String(t.fbaNo).length > 19);
      if (fbaNoFlag !== -1) {
        this.tableData = val;
        return this.$message.error(`货箱编号长度不能大于19位字符!`);
      }
      const declaredValueFlag = val.findIndex((t) => {
        return isNaN(Number(t.declaredValue));
      });
      if (declaredValueFlag !== -1) {
        this.tableData = val;
        return this.$message.error(`产品申报单价只能为数字!`);
      }

      const numFlag = val.findIndex((t) => {
        return isNaN(Number(t.num));
      });
      if (numFlag !== -1) {
        this.tableData = val;
        return this.$message.error(`产品申报数量只能为数字!`);
      }
      const totalPriceFlag = val.findIndex((t) => {
        return isNaN(Number(t.totalPrice));
      });
      if (totalPriceFlag !== -1) {
        this.tableData = val;
        return this.$message.error(`产品申报总价只能为数字!`);
      }

      const weightFlag = val.findIndex((t) => {
        return isNaN(Number(t.weight)) && t.weight;
      });
      if (weightFlag !== -1) {
        this.tableData = val;
        return this.$message.error(`货箱重量只能为数字!`);
      }

      const lengthFlag = val.findIndex((t) => {
        return isNaN(Number(t.length)) && t.length;
      });
      if (lengthFlag !== -1) {
        this.tableData = val;
        return this.$message.error(`货箱长度只能为数字!`);
      }

      const widthFlag = val.findIndex((t) => {
        return isNaN(Number(t.width)) && t.width;
      });
      if (widthFlag !== -1) {
        this.tableData = val;
        return this.$message.error(`货箱宽度只能为数字!`);
      }

      const heightFlag = val.findIndex((t) => {
        return isNaN(Number(t.height)) && t.height;
      });
      if (heightFlag !== -1) {
        this.tableData = val;
        return this.$message.error(`货箱高度只能为数字!`);
      }
      this.tableData = val;
    },
    // 关闭excel编辑
    editTableClose() {
      this.editTable = false;
    },
    handlerAdress(val) {
      this.subForm.name = val.name || "";
      this.subForm.address = val.address || "";
      this.subForm.zipCode = val.zip_code || "";
      this.subForm.city = val.city;
      this.subForm.state = val.state;
      this.subForm.addressId = val.id;
      this.subForm.corporation = val.corporation || "";
      this.subForm.recipient = val.recipient || "";
      if (this.subForm.type === 2) {
        this.subForm.phone = val.phone || "";

        //
      }
    },
    drawerSubmit() {
      let row = this.$refs.wlh.selectRow;
      if (row) {
        this.getDetails(row.waybill_id).then(() => {
          this.drawerShow = false;
        });
      } else {
        this.drawerShow = false;
      }
    },
    // 返回
    goBack() {
      this.$router.go(-1);
    },
  },
  filters: {
    typeStr(val) {
      let str = "";
      if (val == 1) {
        str = "FBA运单";
      } else if (val == 2) {
        str = "非FBA运单";
      }
      return str;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-form {
  min-width: 1300px;
}

.fd-outer {
  padding: 5px 30px;
}

.fd-s1 {
  font-size: 20px;
  color: #fb4e0c;
}

.fd-s2 {
  .s1 {
    font-size: 14px;
    color: #5c5c5c;
  }

  .s2 {
    font-size: 14px;
    color: #9a9a9a;
  }
}

.fd-once {
  display: flex;
  justify-content: flex-start;
}

.fd-inner {
  margin-top: 20px;

  .fd-inner-title {
    font-size: 16px;
    font-weight: 600;
  }

  .fd-img1 {
    width: 180px;
    height: 167px;
  }
}

.b-atn {
  cursor: pointer;
}

.fd-address {
  display: flex;
  justify-content: flex-start;

  .d1 {
    .s1 {
      font-size: 12px;
      color: #cccccc;
    }

    .s2 {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
  }

  .d2 {
    margin-top: 13px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.fd-dm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fd-dm-d1 {
  span {
    display: inline-block;
    margin-left: 15px;
    color: #fb4d0b;
    font-size: 16px;
  }
}

.fd-line {
  margin-top: 5px !important;
}

.ant-col {
  display: flex;
  align-items: center;
}

.ant-form-item {
  margin-bottom: 10px;
}
</style>
